.services-marg {
  margin-top: 4rem;

  .services-bg {
    background-image: url('../img/services.jpeg') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 220px;
    margin-bottom: 2rem;

    .service-h1-div {
      padding-top: 5rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    h1 {
      color: #ffffff;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 40px;
      font-weight: 900;
    }
  }

  p {
    color: #3A3A3A;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  .services-cards {
    cursor: pointer;
    margin: auto;
    position: relative;
    margin-bottom: 2rem;

    img {
      object-fit: cover;
      height: 300px;
      width: 100%;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #232323;
  }

  .services-cards:hover .overlay {
    opacity: 0.9;
  }
  
  .text {
    color: #ffffff;
    font-size: 18px;
    font-family: "Marvel", 'Helvetica Neue', Helvetica, sans-serif;
    position: absolute;
    top: 45%;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: center;
    width: 100%;
  }
  
}

.services-img {
  margin-top: 2rem;
  
  img {
    width: 100%;
  }
}

.full-services-marg {
  margin-top: 4rem;
  margin-bottom: 4rem;

  h1 {
    color: #3A3A3A;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 900;
  }

  p {
    color: #3A3A3A;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  ol {
    color: #3A3A3A;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 16px;

    font-style: italic;
  }

  ul {
    color: #3A3A3A;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
  }

  a {
    color: #1e73be;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-bottom: 2rem;
    text-decoration: none;
  }

  a:hover {
    color: #000000;
  }
}