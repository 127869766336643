.contact-us-marg {
  margin-top: 4rem;

  iframe {
    width: 100%;
  }

  .contact-form {
    margin-top: 4rem;

    .contact-form-title {
      color: #3A3A3A;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 40px;
      font-weight: 300;
    }

    p {
      color: #3A3A3A;
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 500;
      margin-bottom: 2rem;
    }

    .contact-form-input {
      margin-bottom: 1.5rem;

      input {
        background: #fafafa;
        color: #666;
        border: 1px solid #ccc;
        border-radius: 0;
        padding: 10px 15px;
        box-sizing: border-box;
        max-width: 100%;
        width: 70%;
      }

      input:focus {
        background: #fff;
        outline: none;
      }

      textarea {
        background: #fafafa;
        color: #666;
        border: 1px solid #ccc;
        border-radius: 0;
        padding: 10px 15px;
        box-sizing: border-box;
        max-width: 100%;
        width: 100%;
      }

      textarea:focus {
        background: #fff;
        outline: none;
      }

      button {
        border: 1px solid transparent;
        background: #55555e;
        cursor: pointer;
        -webkit-appearance: button;
        padding: 10px 20px;
        color: #fff;
      }

      button:hover {
        background: #3c3c42;
      }

      button:focus {
        outline: none;
      }
    }
  }
}