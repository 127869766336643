.footer-padd {
  background-color: #f7f7f9;
  color: #666666;
  padding: 20px;

  span {
    color: #666666;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
  }
}