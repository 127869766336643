.the-team-marg {
  margin-top: 4rem;

  h1 {
    color: #3A3A3A;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 900;
  }

  p {
    color: #3A3A3A;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  a {
    color: #1e73be;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-bottom: 2rem;
    text-decoration: none;
  }

  a:hover {
    color: #000000;
  }

  .team-mates-img {
    margin-bottom: 2rem;
    
    img {
      width: 100%;
    }

    span {
      color: #3A3A3A;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 12px;
      padding-top: 5px;
    }
  }
}