.partner-top-marg {
  margin-top: 4rem;
  margin-bottom: 8rem;

  h1 {
    color: #3A3A3A;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style: italic;
    font-size: 40px;
    font-weight: 100;
    margin-bottom: 0;
  }

  strong {
    color: #3A3A3A;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 900
  }

  .float-img {
    float: left;
    margin-right: 20px;
    width: 200px;
  }

  p {
    color: #3A3A3A;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  ul {
    margin-bottom: 2rem;
  }

  li {
    color: #3A3A3A;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
  }
}