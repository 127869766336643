body {
  margin: 0;
}

.preloader-div {
  background-color: #142054;
  height: 100%;
  text-align: center;
  width: 100%;

  img {
    margin-top: 25%;
    height: 150px;
    width: 150px;
  }

  .preloader-white {
    color: #ffffff;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 16px;
  }

  .preloader-red {
    color: #b11312;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 16px;
  }
}

.carousel {
  border-bottom: 7px solid #b11312;
  margin-bottom: 2rem;
}

.carousel-indicators {
  display: none;
}

.carousel-item {

  img {
    width: 100%;
  }
}

.carousel-control-next {
  display: none;
}

.carousel-control-prev {
  display: none;
}

@media only screen and (min-width: 1px) and (max-width: 767px) {
  .main-content-padd {
    border-bottom: 1px solid #ebebeb;
    // padding-left: 8rem;
    // padding-right: 8rem;
    margin-bottom: 2rem;
  
    .home-main-h5 {
      color: #041045;
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-size: 30px;
      font-weight: 900;
    }
  
    .client-centered-h5 {
      color: #3A3A3A;
      font-size: 35px;
      font-family: Arial, Helvetica, sans-serif;
    }
  
    p {
      color: #3A3A3A;
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 500;
      margin-bottom: 2rem;
    }
  
    a {
      color: #1e73be;
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 500;
      margin-bottom: 2rem;
      text-decoration: none;
    }
  
    a:hover {
      color: #000000;
    }
  }
  
  .main-content-padd:last-child {
    border-bottom: none;
  }
}

@media only screen and (min-width: 768px) {
  .main-content-padd {
    border-bottom: 1px solid #ebebeb;
    padding-left: 8rem;
    padding-right: 8rem;
    margin-bottom: 2rem;
  
    .home-main-h5 {
      color: #041045;
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-size: 30px;
      font-weight: 900;
    }
  
    .client-centered-h5 {
      color: #3A3A3A;
      font-size: 35px;
      font-family: Arial, Helvetica, sans-serif;
    }
  
    p {
      color: #3A3A3A;
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 500;
      margin-bottom: 2rem;
    }
  
    a {
      color: #1e73be;
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 500;
      margin-bottom: 2rem;
      text-decoration: none;
    }
  
    a:hover {
      color: #000000;
    }
  }
  
  .main-content-padd:last-child {
    border-bottom: none;
  }
}



.client-centered-img {
  margin-top: 2rem;
  
  img {
    width: 100%;
  }
}