.navbar {
  border-bottom: 2px solid #1c438a;
}

.navbar-brand {

  img {
    text-align: center;
    width: 275px;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  padding: 10px 20px 0 20px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #dc0000;
}

.navbar-light .navbar-nav .nav-link:focus {
  color: #dc0000;
}

.navbar-collapse {
  margin-top: 1rem;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  max-width: 20rem;
  min-width: 12rem;
}

.dropdown-item {
  color: #222222;
  font-size: 14px;
  white-space: normal;
}