.blog-div {
  margin-bottom: 4rem;

  .blog-img {
    margin-bottom: 2rem;

    img {
      width: 100%;
    }
  }
  
  .blog-title {
    border-bottom: 2px solid #1c438a;
    margin-bottom: 2rem;
    
    h1 {
      color: #3A3A3A;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 40px;
      margin-bottom: 10px;
    }

    span {
      color: #595959;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
    }
  }

  .blog-text {

    p {
      color: #3A3A3A;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 16px;
    }

    ol {
      color: #3A3A3A;
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-size: 16px;

      font-style: italic;
    }

    ul {
      color: #3A3A3A;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 16px;
    }
  }

  .uncat-div {

    .cat-text {
      color: #595959;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
    }
  }

  .uncat-previous-div {

    .previous-link {
      color: #595959;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
    }
  }
}
