.news-media-marg {
  margin-top: 4rem;

  .news-media-card {
    margin-bottom: 1rem;

    .card-img {

      img {
        height: 350px;
        object-fit: cover;
        width: 100%;
      }
    }

    .card-desc {
      background-color: #f4f4f4;
      padding: 10px 15px;

      h4 {
        color: #3A3A3A;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        margin-bottom: 20px;
      }

      p {
        color: #3A3A3A;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        margin-bottom: 24px;
      }

      a {
        color: #3A3A3A;
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        margin-bottom: 2rem;
        text-decoration: none;
      }
    
      a:hover {
        color: #5c5c5c;
      }

      button {
        color: #fff;
        cursor: pointer;
        background-color: #f4524d;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 2rem;
      }

      button:focus {
        outline: none;
      }
    }
  }
}